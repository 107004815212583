:root {
  --background-color: white;
  --text-color: black;
  --header-footer-bg-color: #434779; /* Default light mode background color */
  --header-footer-text-color: white; /* Default light mode text color */
  --button-bg-color: #20366b; /* Light mode button background color */
  --button-text-color: white; /* Light mode button text color */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark-mode {
  --background-color: black;
  --text-color: white;
  --header-footer-bg-color: #222243; /* Dark mode background color */
  --header-footer-text-color: #e0e0e0; /* Dark mode text color */
  --button-bg-color: #000000; /* Dark mode button background color */
  --button-text-color: rgb(255, 255, 255); /* Dark mode button text color */
}

button {
  margin: 20px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.header, .footer {
  background-color: var(--header-footer-bg-color);
  color: var(--header-footer-text-color);
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header .nav-links {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;  
}

.header .user-info {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
}

.header a, .header p, .header button {
  color: var(--header-footer-text-color);
  background-color: rgb(47, 47, 109);
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header a:hover, .header p:hover, .header button:hover {
  background-color: rgb(62, 42, 164);
}

.header span {
  color: var(--header-footer-text-color);
  margin-right: 10px;
}

.header .welcome, .header .dark-mode-toggle {
  padding: 5px 10px; /* Smaller padding for these elements */
}

.footer {
  text-align: center; /* Center-align text */
  padding: 0; /* Add padding for better spacing */
  position: relative; /* Changed from fixed to relative */
  width: 100%; /* Set the width of the footer */
  bottom: 0; /* Ensure the footer is at the bottom of its containing element */
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Add horizontal padding for spacing */
}

.footer-text {
  flex: 1;
}

.footer button {
  margin-left: 20px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.login-form {
  width: 300px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: rgb(54, 54, 168);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signup-link {
  margin-top: 20px;
  font-size: 14px;
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
  font-size: 16px;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #45a049;
}

/* Center the content and add some padding and background */
.search-result {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
  transition: background-color 0.3s ease;
}

/* Style the heading */
.search-result h2 {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Style the paragraph */
.search-result p {
  color: #555;
  font-size: 1.4em;
  margin: 0;
  font-weight: 700;
}

/* Change background and text color on hover */
.search-result:hover {
  background-color: #faf9e062;
}

.search-result:hover h2 {
  color: #a77dc44f;
  transition: color 0.3s ease;
}

.search-result:hover p {
  color: #0e0017ea;
  transition: color 0.3s ease;
}

/* Loading animation */
@keyframes loadingAnimation {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

.loading {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  animation: loadingAnimation 1s infinite;
}

.sql-practice {
  text-align: center;
  margin: 20px;
  overflow: auto; /* Ensure content is not cut off */
}

.sql-practice .explanation {
  border: 1px solid #ccc; /* Add border around explanation */
  padding: 20px;
  text-align: left; /* Left-justify text */
  margin: 20px auto; /* Center box with margins */
  max-width: 800px; /* Limit width for readability */
}
.options {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .options {
    flex-direction: column; /* Stack options vertically on mobile */
    align-items: center; /* Center align options */
    gap: 0; /* Remove gap between options */
    width: 100%; /* Full width for mobile */
  }
}
.option {
  padding: 10px;
  margin: 5px;
  border: 1px solid #fff4f4;
  cursor: pointer;
  width: 95%;
  text-align: left;
  word-wrap: break-word; /* Break long words */
  white-space: normal; /* Preserve whitespace and wrap lines */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: background-color 0.3s, border-color 0.3s;
}

.option:hover {
  background-color: #e0e0e0;
}

.option.correct {
  background-color: green;
  color: white;
}

.option.incorrect {
  background-color: red;
  color: white;
}

.feedback {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
  }

  .header .nav-links {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; /* Full width for mobile */
  }

  .header .user-info {
    flex-direction: row;
    width: 100%; /* Full width for mobile */
    justify-content: space-between;
  }

  .header a, .header p, .header button {
    margin: 5px 0; /* Adjust margin for mobile */
  }

  .footer {
    display: none;
  }
}

.score {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}
